<template>
  <div>
    <b-card class="invoice-preview-card">
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto">{{ $t('inverstor_mails.title') }} <br /></h2>

      </div>
      <hr>
      <div>
        <app-collapse>
          <app-collapse-item :title="$t('complaints.complaint_list.filters.title')">
            <b-card-body>
              <b-form @submit.prevent>
                <b-row>
                  <b-col md="12">
                    <b-row>
                      <!-- Nom ou prénom ou mail du plaignant -->
                      <b-col md="4">
                        <b-form-group :label="$t('inverstor_mails.filter_label_name')" label-for="client"
                          label-cols-md="12">
                          <b-form-input id="client" v-model="filters.fullname" type="text"
                            :placeholder="$t('inverstor_mails.filter_input_name_placehoder')" />
                        </b-form-group>
                      </b-col>
                      <!-- Numéro de téléphone -->
                      <b-col md="4">
                        <b-form-group :label="$t('complaints.complaint_list.filters.phone')" label-for="tel"
                          label-cols-md="12">
                          <b-form-input id="tel" v-model="filters.phone_number" type="number"
                            :placeholder="$t('complaints.complaint_list.filters.phone_placeholder')" />
                        </b-form-group>
                      </b-col>
                      <!-- Objet de la plainte -->
                      <b-col md="4">
                        <b-form-group :label="$t('inverstor_mails.filter_label_subjet')" label-for="tel"
                          label-cols-md="12">
                          <b-form-input id="tel" v-model="filters.subject" type="text"
                            :placeholder="$t('inverstor_mails.filter_input_subjet_placehoder')" />
                        </b-form-group>
                      </b-col>
                      <!-- Message de le plainte -->

                    </b-row>
                  </b-col>
                </b-row>
                <div class="text-right mt-2">
                  <b-button type="submit" variant="warning" @click="gsearch">
                    <b-spinner v-if="isSearchingUsers" small />
                    <feather-icon v-else icon="SearchIcon" class="mr-25" />
                    &nbsp; {{ $t('button.search') }}
                  </b-button>
                </div>
              </b-form>
            </b-card-body>
          </app-collapse-item>
        </app-collapse>
      </div>
    </b-card>

    <b-card>
      <b-table ref="refUserListTable" class="position-relative" :items="foundQuestions" responsive :fields="tableColumns"
        hover primary-key="id" show-empty :empty-text="$t('table_columns.empty_text')" :busy="isSearchingUsers">
        <!-- Custom Header -->
        <template #head()="{ label, field: { key, sortable } }">
          {{ $t(label) }}
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #cell(id)="data">
          <strong>{{ data.index + 1 }}</strong>
        </template>
        <!-- Column: User -->
        <template #cell(subject)="data">
          <div class="">
            <p class="w-100 pt-1">{{ reduct(data.item.subject, 30) }}</p>
          </div>
        </template>


        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <b-button variant="primary" class="mx-0" @click="details(data.item.id)">
              <feather-icon icon="EyeIcon" />
              <span class="text-nowrap"> {{ $t('button.see') }}</span>
            </b-button>
            <b-button variant="danger" class="ml-2" @click="wouldDelete(data.item)">
              <i class="las la-trash-alt la-lg"></i> {{ $t('button.remove') }}</b-button>
          </div>
        </template>
      </b-table>
      <PaginationComponent :table-data="foundQuestions" :per-page="paginationData.perPage"
        :total-rows="paginationData.total" @change="onPaginationChange" />
    </b-card>
    <b-modal id="modal-delete" ref="modal-delete" :title="$t('modal.faq.delete_title')" ok-only ok-variant="danger"
      modal-class="modal-danger" :ok-title="$t('button.remove')" no-close-on-backdrop @ok="remove">
      <b-card-text>
        <h5>{{ $t('modal.faq.delete_text') }}</h5>
      </b-card-text>
    </b-modal>
  </div>
</template>
  
<script>
import {
  BCollapse,
  BCard,
  BCardText,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  VBToggle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BPagination,
  BFormCheckbox,
  VBTooltip,
  VBModal,
  BSpinner,
  BFormTags,
  BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";

import { mapActions } from "vuex";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";
import Ripple from "vue-ripple-directive";
import PaginationComponent from "@/components/PaginationComponent.vue";
import mailsStoreModule from "@/store/mails";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import localstorageService from "@/services/localstorage/localstorage.service";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BFormCheckbox,
    BCard,
    BCardText,
    BCardHeader,
    BCardBody,
    BRow,
    BForm,
    BFormInput,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCollapse,
    BDropdownDivider,
    BSpinner,
    BFormTags,
    BModal,
    vSelect,
    PaginationComponent,
    VBModal,
    AppCollapse,
    AppCollapseItem,

  },

  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  props: {},

  data() {
    return {
      type: null,
      selectedRoles: [],
      faq_section_id: null,
      isSearchingUsers: false,
      foundQuestions: [],
      listSecteurs: [],
      tableColumns: [
        {
          key: "id",
          label: "#",
          sortable: true,
        },
        { key: "fullname", label: "table_columns.fullname", sortable: true },
        {
          key: "contact",
          label: "table_columns.phone",
          sortable: true,
        },
        {
          key: "subject",
          label: "table_columns.subject",
          sortable: true,
        },
        {
          key: "email",
          label: "table_columns.email",
          sortable: true,
        },
        {
          key: "actions",
          label: "table_columns.actions",
        },
      ],


      filters: {
        fullname: null,
        phone_number: null,
        subject: null,

      },
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200, 500],
      currentId: null,

      isFetchingSecteur: false,
    };
  },
  computed: {},
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.gsearch();
      },
    },
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.foundQuestions && this.foundQuestions[val - 1]) {
          this.foundQuestions = this.customCloneDeep(
            this.foundQuestions.slice(0, val - 1)
          );
        }
      },
    },
    "paginationData.currentPage": {
      handler(val, old) {
        this.gsearch();
      },
    },
    "paginationData.perPage": {
      handler(val, old) {
        this.gsearch();
      },
    },
  },

  setup() {
    const requiredStoreModules = [{ path: "mails", module: mailsStoreModule }];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },

  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },
  mounted() {
    this.fetchSecteurs();
  },

  methods: {
    ...mapActions("mails", {
      action_fetchMails: "fetchMails",
      action_deleteMails: "deleteMails",
    }),
    fetchSecteurs() {
      this.isFetchingSecteur = true;
      this.action_fetchMails()
        .then((response) => {
          this.isFetchingSecteur = false;
          this.listSecteurs = response.data.data;
          console.log("this.formload: ", this.listSecteurs);
        })
        .catch((error) => {
          this.isFetchingSecteur = false;
        });
    },
    /*  profilLink(user) {
      console.log(user);
      const media =
        user.media.length > 0
          ? user.media.find((objet) => objet.collection_name === "avatar")
          : null;
      return media ? media.preview_url : null;
    }, */
    reduct(chaine, nombreCaracteres) {
      if (chaine.length <= nombreCaracteres) {
        return chaine;
      }
      const premiersCaracteres = chaine.substring(0, nombreCaracteres) + "...";
      return premiersCaracteres;
    },
    gsearch() {
      this.isSearchingUsers = true;
      this.action_fetchMails({
        type: "investisseur",
        fullname: this.filters.fullname,
        phone_number: this.filters.phone_number,
        subject: this.filters.subject,
        // params for filter by pagination
        page: this.paginationData.currentPage,
        per_page: this.paginationData.perPage,
      })
        .then((response) => {
          // console.log("response: ", response.data);
          this.foundQuestions =
            response.data.data; /* response.data.data.map(e => {
            console.log("faqs1", e)
            localstorageService.getUserData().roles.forEach(element => {
              console.log("faqs2", element)
              e.roles.map(i => {
                if (element.alias == i.alias) {
                  this.foundQuestions.push(e)
                }
              })
            });
          }); */
          console.log("mails", this.foundQuestions);
          this.isSearchingUsers = false;
          // this.$emit('updateUsers', response.data.data)
          // --------------------------------------------------------------
          this.paginationData.total = response.data.meta.total || 0;
          if (this.paginationData.metaData.from === 0) {
            this.paginationData.metaData.from += 1;
          } else {
            this.paginationData.metaData.from =
              this.paginationData.perPage * this.paginationData.currentPage -
              this.paginationData.perPage;
          }
          this.paginationData.metaData.to =
            this.paginationData.perPage * this.paginationData.currentPage -
            this.paginationData.perPage +
            response.data.meta.total;
          // --------------------------------------------------------------
        })
        .catch((error) => {
          this.isSearchingUsers = false;
          console.log(error);
        });
    },
    wouldDelete(item) {
      this.currentId = item.id;
      this.$refs["modal-delete"].show();
    },
    remove() {
      this.isSearchingUsers = true;
      this.action_deleteMails(this.currentId)
        .then(async (res) => {
          if (res.status === 204) {
            this.isSearchingUsers = false;
            this.currentId = null;
            this.gsearch();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Opération réussie",
                icon: "ThumbsUpIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          this.isSearchingUsers = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage;
      this.paginationData.perPage = val.perPage;
    },
    details(id) {
      console.log("détails");
      this.$router.push({ name: "config-invertors-mails-details", params: { id } });
    },

  },
};
</script>
  
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-preview-wrapper {
  .row.invoice-preview {
    .col-md-8 {
      max-width: 100%;
      flex-grow: 1;
    }

    .invoice-preview-card {
      .card-body:nth-of-type(2) {
        .row {
          >.col-12 {
            max-width: 50% !important;
          }

          .col-12:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  // Action Right Col
  .invoice-actions {
    display: none;
  }
}
</style>
  